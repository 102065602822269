<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="状态">
          <el-select v-model="status" placeholder="请选择" style="width: 100px">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="DataList" v-loading="is_loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="教练名称" prop="real_name"></el-table-column>
        <el-table-column label="保证金" prop="bond"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="申请注销时间" prop="create_time"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <el-tag type="success" v-if="row.status == 1">已注销</el-tag>
            <el-tag type="info" v-if="row.status == 0">待审核</el-tag>
            <el-tag type="danger" v-if="row.status == 2">审核拒绝</el-tag>
          </template>
        </el-table-column>
         <el-table-column label="说明" prop="status">
          <template slot-scope="{ row }">
           <div class="ell" @click="openRemark(row)">{{row.remark}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="{ row }">
            <div class="flex flex-wrap">
              <el-button type="primary" @click="toDetails(row)" size="small">查看详情</el-button>
        
                <el-button   @click="handleopen(row, 1)" slot="reference" type="success" v-if="row.status == 0" size="small">通过</el-button>
              
              <el-button @click="handleopen(row, 2)" slot="reference" type="danger" v-if="row.status == 0"
                size="small">拒绝</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy @currentPageChange="changeCurrentPage" :currentPage="currentPage" :total="Total"></paging-fy>
    </el-row>

 <el-dialog title="说明" :visible.sync="remarkShow" width="30%">
     {{currow.remark}}
      <span slot="footer" class="dialog-footer">
        <el-button @click="remarkShow = false">关闭</el-button>
      </span>
    </el-dialog>

<el-dialog title="提示" :visible.sync="agreeVisible" width="30%">
      <el-form>
        <el-form-item label="">
         是否注销教练退回保证金？
        </el-form-item>
        <el-form-item label="说明">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="remark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agreeVisible = false">取 消</el-button>
        <el-button type="primary" @click="update(1)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="拒绝理由">
          <el-radio v-model="remark" label="课程没有上完">课程没有上完</el-radio>
          <el-radio v-model="remark" label="不符合要求">不符合要求</el-radio>
        </el-form-item>
        <el-form-item label="其他理由">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="remark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="update(2)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mechanismSelect from "../../components/select/mechanismSelect.vue";
import userSelect from "../../components/select/userSelect.vue";
export default {
  components: {
    mechanismSelect,
    userSelect,
  },
  data() {
    return {
      nav: { firstNav: "教练管理中心", secondNav: "教练注销申请列表" },
      DataList: [],
      is_loading: true,
      currentPage: 1,
      Total: 0,
      status: "",
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已注销",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      user_id: "",
      mechanism_id: "",
      price: "",
      listPageChild: [],
      teaching_categories: "",
      name: "",
      phone: "",
      remark: "",
      dialogVisible: false,
      agreeVisible: false,
      currow: "",
      remarkShow:false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    openRemark(row){
      this.currow = row
      this.remarkShow = true
    },
    toDetails(row) {
      this.$router.push({ name: "coachDetails", query: { id: row.coach_id } });
    },
    handleopen(row, status) {
      this.currow = row;
      if (status == 2) {
        this.dialogVisible = true;
      } else {
        this.agreeVisible = true;
      }
    },
    //更新状态
    update(status) {
      let url = "/user/coachLogoutRecord/update";
      let data = {
        id: this.currow.id,
        status,
        remark: this.remark,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.dialogVisible = false;
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //列表
    getList() {
      this.is_loading = true;
      let url = "/user/coachLogoutRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.status ?? null,
      };
      if (this.teaching_categories) {
        params.teaching_categories = this.teaching_categories;
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>

<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }
}
.ell{
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ell:hover{
  color: rgb(25, 92, 194);
  cursor: pointer;
}
</style>
